import React, { FC } from "react";
// libs
import dynamic from "next/dynamic";
// types
import { IReview } from "src/types";
// components
const Hero = dynamic(import("src/components/containers/clinics/hero/Hero"), {
  ssr: true,
});
const Customers = dynamic(import("src/components/containers/clinics/customers/Customers"), {
  ssr: true,
});
const FeaturedPost = dynamic(
  import("src/components/containers/clinics/featuredPost/FeaturedPost"),
  { ssr: true },
);
const Features = dynamic(import("src/components/containers/clinics/features/Features"), {
  ssr: true,
});
const Integrations = dynamic(
  import("src/components/containers/clinics/integrations/Integrations"),
  {
    ssr: true,
  },
);
const UseCases = dynamic(import("src/components/containers/clinics/useCases/UseCases"), {
  ssr: true,
});
const Stats = dynamic(import("src/components/containers/clinics/stats/Stats"), {
  ssr: true,
});
const Testimonial = dynamic(import("src/components/containers/clinics/testimonial/Testimonial"), {
  ssr: true,
});
const Action = dynamic(import("src/components/containers/clinics/action/Action"), { ssr: true });
// const Pricing = dynamic(import("src/components/containers/clinics/pricing/Pricing"), { ssr: true });

interface IClinicsContainer {
  reviews: IReview[];
}

const ClinicsContainer: FC<IClinicsContainer> = ({ reviews }) => {
  return (
    <>
      <Hero />
      <Customers />
      <UseCases />
      <Stats />
      <Testimonial />
      <Features />
      <Integrations />
      {/* <Pricing /> */}
      <Action />
      <FeaturedPost />
    </>
  );
};

export default ClinicsContainer;
